import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const ErrorPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{}}>
        <div className="py-32 text-center">
          <h1 className="text-8xl">404</h1>
          <p>Seite nicht gefunden</p>
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default ErrorPage
